export const environment = {
  production: true,
  apiUrl: 'https://v2.waleedalmarhoon.com/backend/api/',
  productAdd: 'product/add/',
  productList: 'product/list/',
  productDelete: 'product/delete/',
  storeTotals: 'stores/totals/',
  stockAdd: 'stock/add',
  stockList: 'stock/list/',
  stockMoveList: 'stock/stock_move_list',
  supplierList: 'supplier/list/',
  supplierAdd: 'supplier/add/',
  stockMove: 'stock/move/',
  customerList: 'customer/list/',
  customerAdd: 'customer/add/',
  expenseAdd: 'transaction/addExpense',
  transactionList: 'transaction/list',
  purchaseList: "purchase/list/",
  purchaseAdd: "purchase/add",
  purchaseDelete: "purchase/delete",
  productByPurchase: "purchase/products/",
  purchaseGet: "purchase/get",
  stockView: 'stock/view',
  stockTotals: 'stock/totals',
  expenseDelete: 'transaction/expenseDelete',
  productSaleList:'sale/saleproduct/',
  saleAdd: 'sale/add',
  saleList: 'sale/list/',
  saleDelete: 'sale/delete',
  customerDelete: 'customer/delete',
  userRegister: 'user/register',
  userLogin: 'user/login',
  saleTotal: 'sale/totals',
  productSaleTotal:'sale/productsaletotals/',
  transactionTotal: 'transaction/totals',
  userList: 'user/list',
  userGet:"user/get/",
  userGetModules:"user/get_modules/",
  userGetStores:"user/get_stores/",
  userStatus: 'user/setstatus',
  userDelete: 'user/delete',
  userAdmin: 'user/isadmin',
  salesCreditPay: 'sale/pay_credit',
  storeAdd:"stores/add",
  storeList:"stores/list/",
  storeGet:"stores/get/",
  storeDelete:"stores/delete/",
  quantityAdd:"stock/add",
  modules:"modules/list",
  productGet:"product/get/",
  saleReturn:"sale/return/",
  customerAreaAdd:"customer/area_add/",
  customerArea:"customer/area/",
  customerAreaDelete:"customer/area_delete/",
  transactionListBySale: 'transaction/listById/',
  isAdmin: false,
  isModules:[]
};
